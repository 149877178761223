<template>
  <div
    id="user-profile"
    class="pb-4"
  >
    <!-- <ProfileHeaderOrder :lineProfile="lineProfile" /> -->

    <!-- 項目 -->
    <b-card
      v-for="(data, index) in fetchData"
      :key="index"
      no-body
      class="mx-2 mx-sm-5"
      :class="index == 0 ? 'mt-5' : 'mt-3'"
    >
      <!-- user suggestion  -->
      <div class="d-flex justify-content-start align-items-start p-75">
        <b-img
          :src="data.reserve.image"
          rounded
          fluid
          class="mr-50 width-50 height-50"
          style="object-fit: cover;"
        />
        <div class="user-page-info">
          <h5 class="font-weight-bolder mb-0">
            {{ data.name }}
          </h5>
        </div>
      </div>

      <div class="px-75 pb-75">
        <b-link class="text-body text-nowrap">
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="profile-icon mr-25"
          />
          <span class="mr-1">{{ dateFormat(data.date) }} {{ data.time }}</span>
        </b-link>
      </div>

      <div class="px-75 pb-75">
        <div style="text-align: right">
          <small class="text-muted">{{ data.reserve.interval }} min</small><span
            v-if="data.reserve.price > 0"
            class="h5 font-weight-bolder text-danger"
          > / ${{ data.reserve.price }}起</span>
        </div>
      </div>
      <!--/ user suggestion  -->
    </b-card>

    <!-- <ClientLiffView @returnProfile="lineProfile = $event" /> -->
  </div>
</template>

<script>
// import { BCard, BCardText, BLink } from 'bootstrap-vue'
import { ref, watch, onUnmounted } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

// components

//
import dataStoreModule from './reserveOrderStoreModule'

export default {
  components: {
  },
  directives: {
    Ripple,
  },
  props: {
    statusIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const APP_STORE_MODULE_NAME = 'app-client-reserve-order'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ref
    const lineProfile = ref(null)

    // Use toast
    const toast = useToast()

    // Use Func
    const fetchData = ref([])
    const fetchAll = status => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchReserveOrders`, { status })
        .then(response => {
          fetchData.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAll(props.statusIndex)

    watch(() => props.statusIndex, val => {
      fetchAll(val)
    })

    const dateFormat = date => {
      const weeks = ['日', '一', '二', '三', '四', '五', '六']
      const week = new Date(date).getDay()
      return `${date}(${weeks[week]})`
    }

    return {
      fetchData, lineProfile, dateFormat,
    }
  },
}
</script>
